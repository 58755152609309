import styled from "styled-components"

import { getBreakdownItem } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/breakdownUtil"
import { TChangePlanBreakdownData } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { colors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { BreakdownCards } from "./BreakDownCards"
import { BreakdownTable } from "./BreakdownTable"

const TABLE_MIN_WIDTH = "500px"

type ChangePlanBreakdownProps = {
  data: TChangePlanBreakdownData
}

export function ChangePlanBreakdown({ data }: ChangePlanBreakdownProps) {
  const { t, langKeys } = useTranslate()

  const breakdownData = data.items.map(getBreakdownItem)

  return (
    <InlineSizeContainer>
      <TableContainer>
        <BreakdownTable data={breakdownData} />
      </TableContainer>

      <CardsContainer>
        <BreakdownCards data={breakdownData} />
      </CardsContainer>

      <TableSummary>
        <MText>
          <MText variant="subtitle">{t(langKeys.billed_now)}</MText>
        </MText>

        <div style={{ textAlign: "end" }}>
          <MText variant="subtitle">{data.billedNow}</MText>
          <MText variant="bodyS" color="secondary">
            {data.vat}
          </MText>
        </div>
      </TableSummary>
    </InlineSizeContainer>
  )
}

const InlineSizeContainer = styled.div`
  container-type: inline-size;
  margin-bottom: ${spacing.XL};
  display: grid;
  row-gap: ${spacing.M};
`

const TableContainer = styled.div`
  @container (width <= ${TABLE_MIN_WIDTH}) {
    display: none;
  }
`

const CardsContainer = styled.div`
  @container (width > ${TABLE_MIN_WIDTH}) {
    display: none;
  }
`

const TableSummary = styled.div`
  border-top: 1px solid ${colors.divider};
  padding-top: ${spacing.M};
  display: grid;
  grid-template-columns: auto 1fr;
`
