import styled from "styled-components"

import { TBreakdownItem } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { colors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type BreakdownTableProps = {
  data: TBreakdownItem[]
}

export function BreakdownTable({ data }: BreakdownTableProps) {
  return (
    <TableBox>
      <TableHeader />

      {data.map((item) => (
        <TableRow key={item.state} item={item} />
      ))}
    </TableBox>
  )
}

const TableBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: ${spacing.M};
`

function TableHeader() {
  const { t, langKeys } = useTranslate()
  return (
    <TableRowBox variant="subtitleS">
      <TableCell $capitalizeFirst>{t(langKeys.state)}</TableCell>
      <TableCell $capitalizeFirst>
        {t(langKeys.home_plural, { count: 0 })}
      </TableCell>
      <TableCell $capitalizeFirst>{t(langKeys.days)}</TableCell>
      <TableCell $capitalizeFirst>
        {t(langKeys.prorated_price_per_home)}
      </TableCell>
      <TableCell $capitalizeFirst textAlign="end">
        {t(langKeys.charge)}
      </TableCell>
    </TableRowBox>
  )
}

export function TableRow({ item }: { item: TBreakdownItem }) {
  const { t, langKeys } = useTranslate()
  return (
    <TableRowBox variant="bodyS" color="secondary">
      <TableCell children={t(langKeys[item.state])} $capitalizeFirst />
      <TableCell children={item.quantity} />
      <TableCell children={item.days_until_renewal} />
      <TableCell children={item.formattedProratedAmount} />
      <TableCell children={item.formattedAmount} textAlign="end" />
    </TableRowBox>
  )
}

const TableRowBox = styled(MText)`
  display: contents;
`

const TableCell = styled(MText)<{
  $capitalizeFirst?: boolean
}>`
  color: ${colors.brandText};
  ${({ $capitalizeFirst }) =>
    !!$capitalizeFirst &&
    `display: "inline-block";
     &:first-letter {
       text-transform: capitalize;
      };
  `};
`
